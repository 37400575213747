import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Categoria from "../components/categorias"
import cartaData from "../content/carta.json"

const IndexPage = () => (
  <Layout>
    <SEO title="Menú" />
    <section className="container mx-auto text-center">
      {/*<h2 className="w-full md:w-2/4 md:mx-auto py-3 px-6 mb-6 bg-red-700 text-2xl font-bold text-white uppercase tracking-widest border-4 border-double">
        {cartaData.title}
      </h2>*/}
    </section>
    {cartaData.categorias.map((data, index) => {
      return null;//<Categoria key={`categoria_${index}`} categoriaNombre={data.nombre} categoriaTipo={data.tipo} categoriaImagen1={data.imagen1} categoriaImagen2={data.imagen2} categoriaPlatos={data.platos} categoriaPrecios={data.precios} />
    })}
    {/*<section className="container mx-auto text-center p-6 mb-6">* * Para información sobre alérgenos consulte a nuestro personal * *</section>*/}
  </Layout>
)

export default IndexPage
